import {Module, NextModule} from '@backstage-components/base';
import React from 'react';
import {
  GuestConsentProvider,
  type GuestConsentProviderDefinition,
} from './GuestConsentProvider';
import {ComponentDefinition} from './GuestConsentProviderDefinition';

type Kind = GuestConsentProviderDefinition['component'];

type Props = GuestConsentProviderDefinition['props'];

export const GuestConsentProviderModule: Module<Kind, Props> = {
  isContent(value): value is GuestConsentProviderDefinition {
    return false;
  },
  isLayout(value): value is GuestConsentProviderDefinition {
    return (
      value.component === ComponentDefinition.reactName &&
      value.cid === ComponentDefinition.id
    );
  },
  tryRender(definition) {
    if (GuestConsentProviderModule.isLayout(definition)) {
      return React.createElement(GuestConsentProvider, definition.props);
    } else {
      return NextModule;
    }
  },
};
