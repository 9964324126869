import {createContext} from 'react';
import type {ConsentStatus} from './GuestConsentProviderDefinition';

/**
 * @private exported for tests
 */
export const GuestConsentContext = createContext<
  GuestConsentContextValue | undefined
>(undefined);
GuestConsentContext.displayName = 'GuestConsentContext';

export interface GuestConsentContextValue {
  /**
   * Whether the guest has provided consent for analytics information to be
   * collected and tied to a token identifier.
   */
  analyticsConsent: ConsentStatus;
  /**
   * Whether the guest has consented to cookies necessary for platform
   * operation. This is _always_ 'accepted'.
   */
  necessaryConsent: ConsentStatus;
}
