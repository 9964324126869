import {GuestConsentContextValue} from './guest-consent-context';

export const GuestConsentChangedEventName =
  'GuestConsentProvider:on-consent-changed';

export type GuestConsentChangedEvent = CustomEvent<GuestConsentContextValue>;

export type GuestConsentChangedHandler = (
  this: HTMLElement,
  ev: GuestConsentChangedEvent
) => void;

declare global {
  // Extends `#addEventListener` and `#removeEventListener` type definition to
  // include an overload for the `AccessCodeSuccessEventName` custom event
  interface HTMLElement {
    addEventListener(
      type: typeof GuestConsentChangedEventName,
      listener: (this: HTMLElement, ev: GuestConsentChangedEvent) => void,
      options?: boolean | AddEventListenerOptions
    ): void;
    removeEventListener(
      type: typeof GuestConsentChangedEventName,
      listener: (this: HTMLElement, ev: GuestConsentChangedEvent) => void,
      options?: boolean | EventListenerOptions
    ): void;
  }
}
