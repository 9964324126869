import {storage} from '@backstage-components/base';
import Cookie from 'js-cookie';
import {useEffect, useRef, useState} from 'react';
import {
  GuestConsentChangedEventName,
  type GuestConsentChangedHandler,
} from './guest-consent-changed-event';
import type {ConsentStatus} from './GuestConsentProviderDefinition';

/**
 * Read analytics token from storage, if one does not exist create one. If the
 * guest has not provided consent for analytics collection returns `undefined`.
 * This hook _may_ be used outside a `GuestConsentContext`.
 */
export function useAnalyticsToken(): string | undefined {
  const token = useRef(readAnalyticsToken());
  // This listens for events indicating the guest consent has changed and
  // updates internal state based on them rather than reading from
  // `useGuestConsent` because `useAnalyticsToken` may be used outside of the
  // `GuestConsentProvider` required by the `useGuestConsent` hook.
  const [analyticsConsent, setAnalyticsConsent] =
    useState<ConsentStatus>('default');
  useEffect(() => {
    const onGuestConsentChanged: GuestConsentChangedHandler = (event) => {
      setAnalyticsConsent((current) =>
        current === event.detail.analyticsConsent
          ? current
          : event.detail.analyticsConsent
      );
    };
    document.body.addEventListener(
      GuestConsentChangedEventName,
      onGuestConsentChanged
    );
    return () => {
      document.body.removeEventListener(
        GuestConsentChangedEventName,
        onGuestConsentChanged
      );
    };
  }, []);
  // If a guest has not explicitly accepted analytics collection do not provide
  // a value for the analytics token.
  if (analyticsConsent !== 'accepted') {
    return undefined;
  } else if (
    typeof token.current === 'undefined' &&
    typeof window?.crypto?.randomUUID === 'function'
  ) {
    // If not explicitly anonymous and token is missing then create one
    const nextToken = window.crypto.randomUUID();
    updateAnalyticsToken(nextToken);
    token.current = nextToken;
  }
  return token.current ?? undefined;
}

/**
 * Reads the analytics token from cookies or local storage. An analytics token
 * Cookie is preferred over the value in local storage, meaning if an analytics
 * token exists in both cookies and local storage the value in cookies will be
 * returned.
 * @private exported for tests
 */
export function readAnalyticsToken(): string | undefined {
  const initialToken = Cookie.get('analytics-token');
  return initialToken ?? storage.getItem('lcd/analytics-token') ?? undefined;
}

/**
 * Remove the analytics token from both cookie storage an local storage.
 */
export function removeAnalyticsToken(): void {
  Cookie.remove('analytics-token');
  storage.removeItem('lcd/analytics-token');
}

/**
 * Set the given analytics token (`token`) in cookie storage.
 */
function updateAnalyticsToken(token?: string | null): void {
  if (typeof token === 'string') {
    Cookie.set('analytics-token', token, {
      expires: 30, // expires in ~1 month
      sameSite: 'strict',
      secure: true,
    });
  }
}
